import React from "react";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import * as pdcss from "./postDetail.module.css";
import SuggestedPost from "./suggestedPost";
import { MDXRenderer } from "gatsby-plugin-mdx";
// import mainContentImage from "../../assets/coding_image.jpg";

const PostDetail = ({ post }) => {
    const { title, category, image, date } = post.mdx.frontmatter;
    const { body } = post.mdx;
    const pdImage = '../../assets/coding_image.jpg';
    return (
        <div className={pdcss.pdarea}>
            <div className={pdcss.pdContainer}>
                {/* <div className={pdcss.pdareaLeft}></div> */}
                <div className={pdcss.pdareaCenter}>
                    <div className={pdcss.mcContainer}>
                        <h2 className={pdcss.pdHeading}>
                            {title}
                        </h2>
                        <GatsbyImage
                            image={getImage(image)}
                            alt="Main Content Image"
                            className={pdcss.mcImage}
                        />
                        {/* <h4>{category}</h4>
                        <h5>{date}</h5> */}
                        <MDXRenderer>{body}</MDXRenderer>
                    </div>
                    {/* <div className={pdcss.pdfooter}>
                        <div className={pdcss.pdfContainer}>
                            <div className={pdcss.pdfPrevPost}>
                                <h4 className={pdcss.pdfHeading}>Previous Post</h4>
                                <SuggestedPost />
                            </div>
                            <div className={pdcss.pdfNextPost}>
                                <h4 className={pdcss.pdfHeading}>Next Post</h4>
                                <SuggestedPost />
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <div className={pdcss.pdareaRight}>
                    <h3>Popular Posts</h3>
                    <div className={pdcss.pdRightContent}>
                        <SuggestedPost />
                    </div>
                    <div className={pdcss.pdRightContent}>
                        <SuggestedPost />
                    </div>
                    <div className={pdcss.pdRightContent}>
                        <SuggestedPost />
                    </div>
                    <div className={pdcss.pdRightContent}>
                        <SuggestedPost />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default PostDetail;
