// extracted by mini-css-extract-plugin
export var pdarea = "postDetail-module--pdarea--3FJHP";
export var pdContainer = "postDetail-module--pdContainer--3Yu4L";
export var pdHeading = "postDetail-module--pdHeading--1GHYB";
export var pdareaLeft = "postDetail-module--pdareaLeft--38a5q";
export var pdareaCenter = "postDetail-module--pdareaCenter--2UjYh";
export var mcContainer = "postDetail-module--mcContainer--1Xs_L";
export var mcImage = "postDetail-module--mcImage--1hQnN";
export var pdareaRight = "postDetail-module--pdareaRight--oIi4I";
export var pdRightContent = "postDetail-module--pdRightContent--1z4FM";
export var pdfooter = "postDetail-module--pdfooter--UmAlp";
export var pdfContainer = "postDetail-module--pdfContainer--3CK0w";
export var pdfPrevPost = "postDetail-module--pdfPrevPost--smR5r";
export var pdfNextPost = "postDetail-module--pdfNextPost--2ecvh";
export var pdfHeading = "postDetail-module--pdfHeading--424Ql";