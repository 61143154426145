import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import PostDetail from "../components/posts/postDetail";

const PostTemplate = ({ data }) => {
  // console.log(data, ' Post Detail ');
  return (
    <Layout>
      <PostDetail post={data} />
    </Layout>
  )
}

export const query = graphql`
query GetSinglePost($slug: String) {
    mdx(frontmatter: {slug: {eq: $slug}}) {
      frontmatter {
        author
        category
        date(formatString: "MMM, Do YYYY")
        readTime
        slug
        title
      }
      body
    }
  }
`

export default PostTemplate;